import { useEffect,useState } from "react";
import { useBetContext } from "../ContextAndHooks/BetContext";
import LeftSidebar from "./../LeftSidebar";
import HistoryTop from "./../HistoryTop";
import StageBoard from "./../StageBoard";
import { useLocation } from "react-router-dom";
import PreLoader from "../Preloader";
import { useSocket } from "../ContextAndHooks/SocketContext";
import BetControls from "../BetControls";
export default function Dashboard() {
  const location = useLocation();
  const socket = useSocket();
  const { state, dispatch } = useBetContext();
  const { gameStarted } = state;
const [showOverlay, setShowOverlay] = useState(false);
  useEffect(() => {
    if (socket) {
      const handlePlaneCounter = (value) => {
        if (value !== 0) {
          dispatch({ type: "planeValue", payload: value });
        } else {
          dispatch({ type: "planeCrashed", payload: true });
        }
      };
      const handleGameStarted = (boolean) => {
        if (boolean === true) {
          dispatch({ type: "gameStarted", payload: true });
        } else {
          dispatch({ type: "planeCrashed", payload: true });
        }
      };
      socket.on("planeCounter", handlePlaneCounter);
      socket.on("gameStarted", handleGameStarted);
      return () => {
        socket.off("planeCounter", handlePlaneCounter);
        socket.off("gameStarted", handleGameStarted);
      };
    }
  }, [socket, dispatch]);

  useEffect(() => {
    const disableBackButton = () => {
      if (location.pathname === "/") {
        window.history.pushState(null, "", window.location.href);
        window.onpopstate = function () {
          window.history.go(1);
        };
      } else {
        window.onpopstate = null;
      }
    };
    disableBackButton();
    return () => {
      window.onpopstate = null;
    };
  }, [location]);

  useEffect(() => {
    if (!gameStarted) {
      setShowOverlay(true);
      const initialTimeout = setTimeout(() => {
        setShowOverlay(false);
      }, 2000);

      return () => clearTimeout(initialTimeout);
    }
  }, [gameStarted]);

  // Second useEffect for the secondary 8-10 seconds overlay
  useEffect(() => {
    if (!gameStarted) {
      const secondaryTimeout = setTimeout(() => {
        setShowOverlay(true);
        const hideOverlayTimeout = setTimeout(() => {
          setShowOverlay(false);
        }, 2000);

        // Clean up the inner timeout
        return () => clearTimeout(hideOverlayTimeout);
      }, 8000);

      // Clean up the outer timeout
      return () => clearTimeout(secondaryTimeout);
    }
  }, [gameStarted]); 


  return (
    <div className="dark-bg-main overflow-hidden">
      <div className="main-container">
        <LeftSidebar gameStarted={gameStarted} />
        <div className="right-sidebar">
          <div className="game-play">
            <HistoryTop />
            {gameStarted ? <StageBoard /> : <PreLoader />}
            <BetControls />
  {!gameStarted && showOverlay && (
            <div
              style={{
                position: 'fixed',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                backgroundColor: 'rgba(0, 0, 0, 0.5)', // Semi-transparent black
                zIndex: 1000, // Ensure the overlay is above other elements
                pointerEvents: 'auto' // Enable pointer events on the overlay
              }}
            ></div>
          )}
          </div>
        </div>
      </div>
    </div>
  );
}
